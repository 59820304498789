import React from 'react'
import * as S from './styled'
const Dane = ({
  description,
  links,
  slug,
  photos,
  photosDescription,
  moreEN,
  moreDE,
}) => {
  return (
    <>
      <S.P>{description}</S.P>
      <S.Link href={slug} btn>
        {moreEN && 'More'}
        {moreDE && 'Mehr'}
        {!moreEN && !moreDE && 'Více'}
      </S.Link>

      {links && (
        <div>
          <S.UnderlineYellow />
          {links.map(link => (
            <S.Link key={link.slug} href={slug + link.slug}>
              {link.name}
            </S.Link>
          ))}
        </div>
      )}
      {photos && <div className="row"></div>}
    </>
  )
}

export default Dane
