import React from 'react'
import Layout from '../../components/Default/Layout'
import PageHeader from '../../components/PageHeader/PageHeader'
import '../../components/ParagraphUnderPhoto/styles.css'
import Accordion from '../../components/Accordion/Accordion'
import AccordionTemplate from '../../components/Services/AccordionTemplate'
import Content from '../../../content/accordion/o-nas'
import { graphql } from 'gatsby'
import { H2Blue, UnderlineYellow } from '../../components/Typography'
const Onas = ({ data: { page } }) => {
  const { frontmatter } = page
  return (
    <Layout
      meta={page.frontmatter.meta || false}
      title={page.frontmatter.title || false}
    >
      <PageHeader
        title={frontmatter.title}
        backgroundImage={frontmatter.image.childImageSharp.fluid}
      />

      <div className="Paragraph--Wrapper container">
        <p>{frontmatter.description}</p>
        <H2Blue>Klienti</H2Blue>
        <UnderlineYellow />
        <p style={{ marginTop: '5.8rem' }}>
          Našimi klienty jsou především právnické osoby menší a střední
          velikosti, pro které je outsourcing účetních a daňových služeb tím
          nejefektivnějším řešením. Vyhledáváme také spolupráci se start-upy,
          ulevujeme jim od účetní i daňové administrativy, pomáháme s
          nastavováním procesů a umožňujeme soustředit se čistě na rozvoj
          byznysu. Vše zpracováváme precizně a efektivně, k čemuž nám pomáhá
          také pokročilý software s cloudovým řešením, který mimo jiné umožňuje
          oddělit administrativu od účetnictví a export veškerých dat je snadný
          a přehledný.
        </p>

        <Accordion
          items={[
            {
              title: Content.nasTym.title,
              description: <AccordionTemplate {...Content.nasTym} />,
            },
            {
              title: Content.prednaskyKonference.title,
              description: (
                <AccordionTemplate {...Content.prednaskyKonference} />
              ),
            },
            {
              title: Content.pripadoveStudie.title,
              description: <AccordionTemplate {...Content.pripadoveStudie} />,
            },
            {
              title: Content.napsaliONas.title,
              description: <AccordionTemplate {...Content.napsaliONas} />,
            },
          ]}
        />
      </div>
    </Layout>
  )
}

export default Onas

export const pageQuery = graphql`
  query Onas {
    page: markdownRemark(frontmatter: { slug: { eq: "o-nas" } }) {
      ...Meta
      frontmatter {
        title
        description
        image {
          childImageSharp {
            fluid(quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`
