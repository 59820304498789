import React from 'react'
import styled from 'styled-components'
import theme from '../../common/theme'

const A = styled.a`
  color: ${theme.color.white};
  display: block;
  font-weight: 700;
  transition: 0.3s;
  cursor: pointer;
  &:hover {
    color: ${theme.color.yellow};
    transition: 0.3s;
  }
`
const ButtonWrap = styled.div`
  padding-top: 22px;
  text-align: ${props => (props.right ? 'right' : 'inherit')};
`

export const P = styled.p`
  padding-top: 25px;
  padding-bottom: 9px;
  font-weight: 400;
`

const AccordionButton = styled.a`
  background-color: ${theme.color.yellow};
  color: ${theme.color.blue};
  padding: 7px 78px !important;
  border-radius: 11px;
  cursor: pointer;
  font-weight: 600;
`

export const Link = ({ children, href, btn, right }) => {
  return (
    <ButtonWrap right={right}>
      {btn ? (
        <AccordionButton className="button-primary-blue-bg" href={href}>
          {children}
        </AccordionButton>
      ) : (
        <A href={href}>{children}</A>
      )}
    </ButtonWrap>
  )
}

export const UnderlineYellow = styled.div`
  margin-top: 24px;
  width: 80%;
  margin-left: auto;
  margin-right: auto;
  height: 0px;
  border: 3px solid ${theme.color.yellow};
  border-radius: 20px;
`
